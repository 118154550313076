import React from "react";

import SEO from "../components/utils/seo";
import Layout from "../components/Layout/layout";

const NotFoundPage = () => (
  <Layout infoPage={{page: "404"}}>
    <SEO title="404: Not found" />
    <h1>Nao encontrado</h1>
    <h3>Você acessou uma rota que não existe :(</h3>
  </Layout>
)

export default NotFoundPage
